<script>
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import FilterAndSortMixin from "@/mixins/FilterAndSortMixin";
import { mapGetters } from "vuex";
import { toRelationString, toInfoData } from "@/utils/transforms";

export default {
  mixins: [ConfirmModalMixin, FilterAndSortMixin, LisaComunicaMixin],
  data() {
    return {
      innerHeight: "",
      innerWidth: "",
      items: [],
      allIds: [], // hasChecks: the list of all fetched ids
      checkAll: false, // hasChecks: v-model for the check all / uncheck all header control
      selectedRows: [], // hasChecks: the list of selected ids
      isLoading: false,
      perPage: false,
      currentPage: 1,
      pageOptions: [10, 15, 20],
      totalItems: 0,
    };
  },
  props: {
    fields: {
      type: Array,
    },
    filterName: String,
    repository: String,
    resource: String,
    noInnerWidth: {
      type: Boolean,
      default: false,
    },
    includeBottomRow: {
      type: Boolean,
      default: false,
    },
    hasChecks: {
      type: Boolean,
      default: false,
    },
    isCheckExclusive: {
      type: Boolean,
      default: false,
    },
    onlyActions: {
      type: Array,
      default: () => ["edit", "details", "infomodal", "destroy"],
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height: {
      get() {
        return this.innerHeight;
      },
      set(val) {
        this.innerHeight = val;
      },
    },
    width: {
      get() {
        return this.innerWidth;
      },
      set(val) {
        this.innerWidth = val;
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    toRelationString,
    toInfoData,
    ...mapGetters("sidebar", ["isNavClose"]),
    ...mapGetters("cache", { getCacheByName: "get" }),
    resize() {
      this.height = window.innerHeight - 350 + "px";
      this.width = window.innerWidth - 90 + "px";
    },
    getQueryString() {
      if (this.repository) {
        const page = this.page ? `&page=${this.page}` : "";
        const perPage = this.perPage ? this.perPage : "";
        return `${this.loadFilter(this.filterName)}` + `${page}` + `${perPage}`;
      }
      return this.loadFilter(this.filterName);
    },
    onSelectRow(value, index, item) {
      // value == value of checkbox (ie. true or false, or whatever is stored in v-model)
      // index == visual index of row (i.e. the index of the row wrt the displayed rows)
      // item == the clicked row item data
      // console.debug("onSelectRow");
      // console.debug(value, index, item, filterName);
      // if (this.isCheckExclusive == true) {
      //   console.debug("checkExclusive: true");
      // } else if (this.isCheckExclusive == false) {
      //   console.debug("checkExclusive: false");
      // } else {
      //   console.debug("checkExclusive: null");
      // }

      if (item.check) {
        if (this.isCheckExclusive) {
          // empty array
          this.selectedRows = [];
          // unselect other evetually selected
          this.unselectAll();
          // reselect this one
          this.items.find((e) => e.id === item.id).check = true;
        }
        // push
        this.selectedRows.push(item.id);
      } else {
        // pop
        this.selectedRows = this.selectedRows.filter((i) => i !== item.id);
      }
      // this sets the global checkbox status (remove if use buttons)
      if (this.selectedRows.length === 0) {
        this.checkAll = false;
      } else if (this.selectedRows.length === this.allIds.length) {
        this.checkAll = true;
      } else if (this.selectedRows.length !== this.allIds.length) {
        this.checkAll = false;
      }
    },
    onSelectAllRows() {
      // this.checkAll ? this.$emit('selectAll') : this.$emit('unselectAll');
      this.checkAll ? this.selectAll() : this.unselectAll();
    },
    selectAll() {
      // this.$refs[this.tableRef].selectedRows = this.$refs[this.tableRef].allIds;
      let extendedData = this.items.map((obj) => ({
        ...obj,
        check: true,
      }));
      this.items = extendedData;
    },
    unselectAll() {
      // this.$refs[this.tableRef].selectedRows = [];
      let extendedData = this.items.map((obj) => ({
        ...obj,
        check: false,
      }));
      this.items = extendedData;
    },
    setPagination(response) {
      if (this.repository === "log") {
        this.totalItems = response.data.totalElements;
        this.perPage = this.perPage ? this.perPage : 10;
      }
    },
    openModal(item, index, button) {
      this.modalResourceId = item.id;
      this.$bvModal.show("modal", button);
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetch();
    },
    fetch() {
      this.isLoading = true;
      // must return a promise!!!
      return this.index(this.getQueryString())
        .then((response) => {
          let data = response.data.content;
          this.setPagination(response);
          if (this.hasChecks) {
            // set the allIds:
            this.allIds = data.map((i) => i.id);
            // add check prop to data
            data = data.map((obj) => ({ ...obj, check: false }));
            //emptyselectedRows
            this.selectedRows = [];
            this.checkAll = false;
          }

          this.items = data;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.items = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onEdit(id, item) {
      this.$emit("edit", id, item);
    },
    onDestroy(id) {
      this.showConfirm({
        yesCallback: () => {
          this.$emit("destroy", id);
        },
        noCallback: null,
        title: "Conferma Eliminazione",
        message: "Vuoi davvero eliminare?",
        yesLabel: "ELIMINA",
        noLabel: "ANNULLA",
      });
    },
  },
};
</script>
